<template>
  <div :class="['comments-cards pt-2', isAnswer ? 'answer-padding' : '']">
    <div class="card-comments bg-black p-3 pb-3">
      <div class="row pe-4">
        <div class="col-auto">
          <router-link :to="{
            name: 'perfil',
            params: { user: comment.usuario.idEducando },
          }" class="text-decoration-none">
            <img v-if="comment.usuario.hasImage && !hasError" :src="comment.usuario.imagem" class="user-img"
              @error="setError" />
            <div v-else class="user-name">
              {{ comment.usuario.nome.charAt(0) }}
            </div>
          </router-link>
        </div>
        <div class="col">
          <div class="d-flex flex-column justify-content-center">
            <div class="user-data mb-4">
              <div class="d-flex flex-row justify-content-between align-items-end">
                <div class="name">
                  <p class="white font-14">
                    <router-link :to="{
                      name: 'perfil',
                      params: { user: comment.usuario.idEducando },
                    }" class="text-decoration-none">
                      {{ comment.usuario.nome }}
                    </router-link>
                  </p>
                  <p class="dark-grey-2 font-12">
                    {{ comment.usuario.profissao }}
                  </p>
                </div>
                <!-- <p class="dark-grey-2">
                  {{ comment.dataComentario }}
                </p> -->
              </div>
            </div>

            <div class="conquests py-2 pt-0">
              <img :src="img" class="me-2" v-for="img in conquistas" :key="img" />
            </div>

            <div class="comment-text mb-4 position-relative">
              <div v-html="comment.texto" class="dark-grey-2" />
            </div>

            <div class="footer-comment">
              <div class="d-flex flex-row justify-content-between align-items-end">
                <div class="statistic d-flex flex-row align-items-end">
                  <IconLabel :icon="isLiked ? 'heartFill' : 'heart'" class="me-3 pointer" :label="likesString"
                    @click.native="handleLike(comment)" />
                  <IconLabel icon="eye" :label="viewsString" />
                </div>
                <div v-if="!isAnswer" data-toggle="collapse" class="answer" :data-target="`#collapse-${comment.id}`">
                  <IconLabel icon="reply" class="pointer" label="Responder" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="collapse my-4 mb-2" :id="`collapse-${comment.id}`">
      <form class="d-flex flex-column" @submit.prevent="saveAnswer()">
        <div class="col row">
          <div class="col-auto">
            <img class="img-perfil ms-4" :src="mountImage(user)" />
          </div>
          <div class="col">
            <FieldTextQuill placeholder="Escreva sua resposta" :required="true" v-model="message" :isDisabled="isPro" />
          </div>
        </div>
        <div class="col justify-content-end text-end">
          <button :disabled="isPro" class="btn bg-btn-pink font-14 mb-1">
            Enviar
          </button>
        </div>
      </form>
    </div>

    <div v-if="hasAnswers(comment)">
      <CommentCard v-for="answer in comment.respostas" :key="answer.id" :comment="answer" :is-answer="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommentsService from "@/service/commentsService";
import IconLabel from "@/components/shared/forms/IconLabel";
import FieldTextQuill from "@/components/shared/forms/FieldTextQuill";

export default {
  name: "CommentCard",

  components: {
    IconLabel,
    FieldTextQuill,
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      allVideos: "temporadas/getAllVideos",
    }),

    currentVideo() {
      return Number(this.$route.params.video);
    },

    likesString() {
      return `${this.likes}`;
    },

    isPro() {
      return this.allVideos.some(
        ({ isPro, id }) => isPro && id === this.currentVideo && !this.user.isPro
      );
    },

    viewsString() {
      return `${this.comment.visualizacoes || 0}`;
    },
  },

  data() {
    this.CommentsService = new CommentsService(this.$resource);
    return {
      message: "",
      likes: 0,
      hasError: false,
      isLiked: false,
      conquistas: [],
    };
  },

  props: {
    comment: {},
    isAnswer: {},
  },

  async created() {
    const params = {
      idTemporadaVideo: this.$route.params.idTemporadaVideo,
      idEducando: this.comment.usuario.idEducando,
      idComentario: this.comment.id,
    };
    this.verifyLiked(params);
    this.visualizar(params);
    this.getLikes(params);
    this.getConquistas(params);
  },

  methods: {
    setError() {
      this.hasError = true;
    },

    saveAnswer() {
      if (this.isPro) return;

      this.$sweet.sweetWait("Aguarde enquanto estamos salvando");
      const params = {
        idTemporadaVideo: Number(this.$route.params.idTemporadaVideo),
        idComentario: this.comment.id,
        comentario: this.message.replace(/\n/g, "<br>"),
      };
      this.CommentsService.salvarResposta(params)
        .then(() => {
          this.message = "";
          this.$sweet.sweetSuccessResp(
            "<h4>Resposta salva!</h4><h5>Seu comentário está sendo analizado por nossa equipe.</h5>",
            5000
          );
        })
        .catch((_) => {
          this.$sweet.sweetError();
        });
    },

    visualizar(params) {
      this.CommentsService.visualizar(params);
    },

    async getConquistas(params) {
      const { body: conquistas } = await this.CommentsService.conquistas(
        params
      );
      this.conquistas = conquistas.map(
        ({ extensaoImagem, id }) =>
          `${this.$baseApiURL}/acesso-arquivo?caminho=conquista&identificador=${id}&extensao=${extensaoImagem}`
      );
    },

    async getLikes(params) {
      const { body } = await this.CommentsService.curtidas(params);
      this.likes = body;
    },

    async verifyLiked(params) {
      const { body } = await this.CommentsService.alunoCurtiu(params);
      if (body.length) this.isLiked = true;
    },

    async handleLike(comment) {
      const params = {
        idComentario: comment.id,
        idTemporadaVideo: this.$route.params.idTemporadaVideo,
      };
      this.isLiked ? this.unlikeThis(params) : this.likeThis(params);
    },

    async unlikeThis(params) {
      this.isLiked = false;
      this.likes--;
      await this.CommentsService.removerCurtida(params);
    },

    async likeThis(params) {
      this.likes++;
      this.isLiked = true;
      await this.CommentsService.curtir(params);
    },

    mountImage({ extensaoImagem, id }) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=educando&extensao=${extensaoImagem}&identificador=${id}`;
    },

    hasAnswers({ respostas }) {
      return Array.isArray(respostas) && respostas.length;
    },
  },
};
</script>

<style lang="sass">
.comments-cards
  .user-name
    width: 50px
    height: 50px
    border-radius: 50%
    display: inline-flex
    justify-content: center
    align-items: center
    text-transform: uppercase
    font-size: 25px
    padding-top: 3px
    background-color: #222
  .img-perfil
    border-radius: 50%
    width: 40px
  .comment-text
    max-width: 100%
    img
      width: 100% !important
      margin-top: 5px
  .answer-padding
    margin-left: 60px
    padding-left: 30px
    border-left: 2px solid rgba(256,256,256, .15)
  .card-comments
    border-radius: 10px
    .user-img
      overflow: hidden
      object-fit: cover
      width: 50px !important
      height: 50px !important
      border-radius: 50% !important
    .conquests
      border-bottom: 1px solid rgba(256,256,256, .25) !important
      margin-bottom: 5px
      img
        max-width: 25px
        border-radius: 50%
</style>
